@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lora&display=swap");

@layer base {
  body {
    @apply text-gray-800;
    background-color: rgba(245, 243, 242, 0.5);
    font-size: 16px;
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-serif font-normal leading-tight;
  }

  h1 {
    margin-top: 0;
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2.0rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .svg-icon {
    width: 2em;
    height: 2em;
  }

  .svg-icon path,
  .svg-icon polygon,
  .svg-icon rect {
    fill: #333;
  }

  .svg-icon circle {
    stroke: #333;
    stroke-width: 1;
  }
}

@layer components {
  .logo {
    @apply mx-auto mb-4;

    max-width: 280px;
    height: auto;
  }

  .hero-img {
    @apply mx-auto rounded-sm object-contain;
    max-height: 600px;
    height: auto;
    width: auto;
  }

  .btn {
    @apply inline-block px-8 py-2 font-semibold text-center bg-yellow-500 hover:bg-yellow-400 text-black transition duration-150 ease-in-out;
  }

  .btn-tall {
    @apply py-3;
  }

  .upper-title {
    @apply tracking-widest uppercase font-sans mb-2;
  }

  .title {
    @apply mb-4 text-3xl font-extrabold text-center text-white;
  }

  .title-small {
    @apply text-2xl font-bold;
  }

  .intro {
    @apply text-xl text-gray-500 text-center mx-8 mb-12 max-w-sm;
  }

  .text {
    @apply text-xl text-gray-500;
  }

  .section {
    background: linear-gradient(180deg, rgba(245, 243, 242, 0.5), #fff);
  }

  .block-3 {
    @apply max-w-sm p-4 mx-auto md:max-w-full md:mx-0 md:w-1/2 lg:w-1/3;
  }

  .small-link {
    @apply text-yellow-500 uppercase font-semibold;
  }
}

@layer utilities {
}
